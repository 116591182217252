.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  box-shadow: inset 1 2 3 1000px rgba(20, 20, 140, 190);
  object-fit: contain;
  position: absolute;
  padding-left: 7%;
  top: 0;
  left: 0;
  margin-top: 60px;
}

.hero-container > h1 {
  color: #000;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #000;
  font-size: 32px;
  /* font-family: "Times New Roman", Times, serif; */
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    /* font-size: 70px; */
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    /* font-size: 50px; */
    margin-top: -100px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > p {
    font-size: 30px;
    /* margin-top: -150px; */
  }
}

@media screen and (max-width: 768px) {
  .hero-container > p {
    font-size: 20px;
    /* margin-top: -100px; */
  }
}
