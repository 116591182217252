* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "PT Sans", sans-serif; */
}

.colors,
.brands,
.contact,
.colors {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.brands {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.slider {
  /* position: relatives; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  /* width: 1000px;
    height: 600px; */
  min-width: 100%;
  border-radius: 2px;
}

/* .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
} */

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 768px) {
  .right-arrow,
  .left-arrow {
  display: none;
  }
  }

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  /* min-width: 100%;
    min-height: 100%; */
  width: 50vw;
  height: 50vw;
}

.slide.active {
  opacity: 0.5;
  transition-duration: 1s;
  transform: scale (1.08);
}
