@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css"); */
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");

/* .cursive {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
} */

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

