@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap);
@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
.navbar {
  
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;

  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  padding-left: 116px;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
}

.fa-bars {
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* @media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #EEEEEE;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
} */
 

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #C0C6C8;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(15%, 50%);
            transform: translate(15%, 50%);
  }

  .menu-icon {
    display: flex; /* Changed from "block" to "flex" */
    align-items: center; /* Added to vertically center the icon */
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-150%, 120%);
            transform: translate(-150%, 120%); /* Adjusted the transform value */
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "PT Sans", sans-serif; */
}

.colors,
.brands,
.contact,
.colors {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.brands {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.slider {
  /* position: relatives; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  /* width: 1000px;
    height: 600px; */
  min-width: 100%;
  border-radius: 2px;
}

/* .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
} */

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

@media screen and (max-width: 768px) {
  .right-arrow,
  .left-arrow {
  display: none;
  }
  }

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  /* min-width: 100%;
    min-height: 100%; */
  width: 50vw;
  height: 50vw;
}

.slide.active {
  opacity: 0.5;
  transition-duration: 1s;
  -webkit-transform: scale (1.08);
          transform: scale (1.08);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css"); */

/* .cursive {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
} */

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.footer-container {
  /* background-color: #242424; */
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #000;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #000;
}

.footer-links {
  /* remove justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-link-wrapper {
  display: flex;
  flex-wrap: wrap; /* add this to wrap the links when they exceed the width of the container */
  justify-content: center;
  margin: 16px 0; /* add this to create space between the links and the container */
}


/* .footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

.footer-link-wrapper {
  display: flex;
} */

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  align-items: center;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #000;
}

.footer-link-items a {
  color: #000;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  marginbottom: 32px;
}

footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

footer-input::placeholder {
  color: #b1b1b1;
}

.social-logo {
  color: #fff;
  justify-left: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-self: center;
  margin-bottom: 16px;
}

website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }
}

.onepost__header-text {
    font-size: 6rem;
    /* font-size: 2rem 6rem 2rem 6rem; */
}




.homepageColors {
  display: grid;
  /* gridTemplateColumns: 1fr 1fr 1fr 1fr, */
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;
  gap: 4px;
  width: 400px;
  height: "auto";
  padding: 500px;
}
.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  box-shadow: inset 1 2 3 1000px rgba(20, 20, 140, 190);
  object-fit: contain;
  position: absolute;
  padding-left: 7%;
  top: 0;
  left: 0;
  margin-top: 60px;
}

.hero-container > h1 {
  color: #000;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #000;
  font-size: 32px;
  /* font-family: "Times New Roman", Times, serif; */
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    /* font-size: 70px; */
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    /* font-size: 50px; */
    margin-top: -100px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > p {
    font-size: 30px;
    /* margin-top: -150px; */
  }
}

@media screen and (max-width: 768px) {
  .hero-container > p {
    font-size: 20px;
    /* margin-top: -100px; */
  }
}

.homepageBrands {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  align-items: start;
  justify-content: space-between;
  border-radius: 25px;
}

.pics {
  position: relative;
  content: "";
}

.pics:hover {
  opacity: 0.3;
}

.pics:hover:after {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(10px, 10px);
          transform: translate(10px, 10px); /* add some padding from the top-left corner */
  font-size: 24px;
  color: black;
  text-align: left;
  white-space: nowrap;
  z-index: 1; /* ensure the text appears above the image */
}



@media (max-width: 768px) {
  .homepageBrands {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}


@media (max-width: 480px) {
  .homepageBrands {
    display: block;
    grid-template-columns: repeat(1, 1fr);

  }
  .homepageBrands .pics {
    margin-bottom: 5px;
  }
}








