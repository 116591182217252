.homepageBrands {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  align-items: start;
  justify-content: space-between;
  border-radius: 25px;
}

.pics {
  position: relative;
  content: "";
}

.pics:hover {
  opacity: 0.3;
}

.pics:hover:after {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 10px); /* add some padding from the top-left corner */
  font-size: 24px;
  color: black;
  text-align: left;
  white-space: nowrap;
  z-index: 1; /* ensure the text appears above the image */
}



@media (max-width: 768px) {
  .homepageBrands {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}


@media (max-width: 480px) {
  .homepageBrands {
    display: block;
    grid-template-columns: repeat(1, 1fr);

  }
  .homepageBrands .pics {
    margin-bottom: 5px;
  }
}







